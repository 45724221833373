import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Layout } from '../components/Layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Work`}</h2>
    <h3>{`The Omni Group`}</h3>
    <p>{`Web Engineering Lead `}<strong parentName="p">{`·`}</strong>{` February 2020-Present`}</p>
    <p>{`Senior Software Engineer `}<strong parentName="p">{`·`}</strong>{` January 2018-February 2020`}</p>
    <p>{`Senior Web Developer `}<strong parentName="p">{`·`}</strong>{` June 2017-January 2018`}</p>
    <p>{`Senior Front-End Web Developer `}<strong parentName="p">{`·`}</strong>{` March 2017-June2017`}</p>
    <h3>{`iSoftStone`}</h3>
    <p>{`Data Analyst II `}<strong parentName="p">{`·`}</strong>{` April 2016`}</p>
    <p>{`Senior Web Developer `}<strong parentName="p">{`·`}</strong>{` June 2015-April 2016`}</p>
    <p>{`Senior Technical Writer, Principal Trainer `}<strong parentName="p">{`·`}</strong>{` April 2015-June 2015`}</p>
    <h3>{`SpaceX`}</h3>
    <p>{`Web Developer, Technical Writer `}<strong parentName="p">{`·`}</strong>{` October 2013-August 2014`}</p>
    <p>{`Liquid Propulsion Systems Analyst `}<strong parentName="p">{`·`}</strong>{` October 2013-August 2014`}</p>
    <h3>{`Omni Hotels`}</h3>
    <p>{`Bellman, Valet `}<strong parentName="p">{`·`}</strong>{` February 2013-August 2014`}</p>
    <h2>{`Education`}</h2>
    <h3>{`The University of Georgia`}</h3>
    <p>{`Doctor of Philosophy (PhD) `}<strong parentName="p">{`·`}</strong>{` Mathematics (Fluid Dynamics) `}<strong parentName="p">{`·`}</strong>{` 2013-2014`}</p>
    <p>{`Master of Applied Mathematical Sciences (MAMS) `}<strong parentName="p">{`·`}</strong>{` Mathematics (Fluid Dynamics) `}<strong parentName="p">{`·`}</strong>{` 2012-2013`}</p>
    <h3>{`Jacksonville University`}</h3>
    <p>{`Bachelor of Science (BS) `}<strong parentName="p">{`·`}</strong>{` Mathematics, Physics `}<strong parentName="p">{`·`}</strong>{` 2010-2012`}</p>
    <p>{`Bachelor of Arts (BA) `}<strong parentName="p">{`·`}</strong>{` Creative Writing `}<strong parentName="p">{`·`}</strong>{` 2010-2012`}</p>
    <h3>{`Florida State College at Jacksonville`}</h3>
    <p>{`Associate of Arts (AA) `}<strong parentName="p">{`·`}</strong>{` Mathematics `}<strong parentName="p">{`·`}</strong>{` 2008-2010`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      